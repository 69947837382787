.VirtualSelectGrid {
  z-index: 1;
}

.VirtualizedSelectOption {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  cursor: pointer;
}
.VirtualizedSelectFocusedOption {
  background-color: rgba(0, 126, 255, 0.1);
}
.VirtualizedSelectDisabledOption {
  opacity: 0.5;
}
.VirtualizedSelectSelectedOption {
  font-weight: bold;
}
